<template>
  <div>
    <el-form v-model="filterData" size="small" label-width="70px">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <el-form-item label="agency" label-width="45px">
            <el-input clearable v-model="filterData.agency"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" align="right">
          <el-form-item label-width="0">
            <el-button
              type="primary"
              icon="el-icon-search"
              :loading="loading.list"
              @click="getList(1)"
              >Search</el-button
            >
            <el-button type="success" icon="el-icon-plus" :loading="loading.list" @click="add"
              >Create</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      v-loading="loading.list"
      ref="tableRef"
      class="w100"
      height="70vmin"
      stripe
      border
      :data="list"
      size="mini"
      highlight-current-row
    >
      <el-table-column label="ID" prop="id" align="center" width="80"></el-table-column>
      <el-table-column label="agency" prop="agency" align="center" min-width="90"></el-table-column>
      <el-table-column
        label="token"
        prop="token"
        align="center"
        min-width="90"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="appId"
        prop="appId"
        align="center"
        show-overflow-tooltip
        min-width="180"
      ></el-table-column>
      <el-table-column
        label="remark"
        prop="remark"
        align="center"
        min-width="100"
      ></el-table-column>
      <el-table-column label="Option" align="center" width="180">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="mini"
            circle
            @click="edit(scope.row)"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            circle
            size="mini"
            @click="del(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      class="appsflyer_account_dialog"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      :width="fullWidth < 750 ? '96%' : '60%'"
    >
      <el-form
        :model="postData"
        label-width="120px"
        :label-position="fullWidth < 768 ? 'top' : 'right'"
        :rules="rules"
        ref="postData"
        v-loading="loading.dialog"
      >
        <el-form-item label="agency" prop="agency">
          <el-input
            clearable
            v-model="postData.agency"
            :disabled="dialog.title === 'Add' ? false : true"
          ></el-input>
        </el-form-item>
        <el-form-item label="token" prop="token">
          <el-input type="textarea" :rows="8" clearable v-model="postData.token"></el-input>
        </el-form-item>
        <el-form-item label="appId" prop="appId">
          <el-input clearable v-model="postData.appId"></el-input>
        </el-form-item>
        <el-form-item label="remark" prop="remark">
          <el-input clearable v-model="postData.remark"></el-input>
        </el-form-item>
        <el-form-item label-width="0px" class="align-r">
          <el-button @click="close">取 消</el-button>
          <el-button type="primary" @click="submit('postData')">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import {
    fetchAppsFlyerAgencyDetails,
    fetchAppsFlyerAgencyPage,
    handleAppsFlyerAgencyAdd,
    handleAppsFlyerAgencyDel,
    handleAppsFlyerAgencyUpdate,
  } from '@/api/report';
  import { myMixin } from '@/mixins/mixins.js';
  let postDataObj = {
    agency: '',
    token: '',
    remark: '',
    appId: '',
  };
  export default {
    name: 'AppsflyerAccount',
    mixins: [myMixin],
    data() {
      return {
        filterData: {},
        option: {
          pidList: [],
          appIdList: [],
          pidAppidObject: {},
        },
        fullWidth: document.documentElement.clientWidth,
        list: [],
        loading: {
          list: false,
          dialog: false,
        },
        postData: {},
        pageParam: {
          page: 1,
          pageSize: 20,
        },
        dialog: {
          title: 'Add',
          visible: false,
        },
        rules: {
          agency: [{ required: true, message: 'Please enter agency', trigger: 'blur' }],
          token: [{ required: true, message: 'Please enter token', trigger: 'blur' }],
          appId: [{ required: true, message: 'Please enter appId', trigger: 'blur' }],
        },
        appsList: [],
        allPidAppid: [],
      };
    },
    mounted() {
      this.getList();
    },
    methods: {
      // 获取数据列表
      getList() {
        let param = {
          ...this.filterData,
        };
        this.loading.list = true;
        fetchAppsFlyerAgencyPage(param)
          .then((res) => {
            this.list = res.result;
            this.loading.list = false;
          })
          .catch(() => {
            this.loading.list = false;
          });
      },
      add() {
        this.dialog.title = 'Add';
        this.dialog.visible = true;
        this.postData = Object.assign({}, postDataObj);
      },
      edit(row) {
        this.dialog.title = 'Update';
        this.dialog.visible = true;
        this.loading.dialog = true;
        fetchAppsFlyerAgencyDetails(row.id)
          .then((res) => {
            this.postData = res.result;
            this.loading.dialog = false;
          })
          .catch(() => {
            this.loading.dialog = false;
          });
      },
      close() {
        this.dialog.title = '';
        this.postData = Object.assign({}, postDataObj);
        this.dialog.visible = false;
      },
      del(row) {
        if (row.id === '') return;
        this.$confirm('Are you sure you want to delete?', 'tip', {
          confirmButtonText: 'yes',
          cancelButtonText: 'no',
          type: 'warning',
        }).then(() => {
          this.loading.list = true;
          handleAppsFlyerAgencyDel(row.id)
            .then(() => {
              this.$message.success('Information remove successfully');
              this.getList();
              this.loading.list = false;
            })
            .catch(() => {
              this.$message.error('Information deletion failed');
              this.loading.list = false;
            });
        });
      },
      submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.dialog.title === 'Add') {
              this.loading.dialog = true;
              const query = Object.assign({}, this.postData);
              handleAppsFlyerAgencyAdd(query)
                .then((res) => {
                  if (res.success === true) {
                    this.$message.success(res.message);
                    this.loading.dialog = false;
                    this.dialog.visible = false;
                    this.postData = Object.assign({}, postDataObj);
                    this.getList();
                  } else {
                    this.$message.error(res.message);
                    this.loading.dialog = false;
                    this.dialog.visible = false;
                    this.postData = Object.assign({}, postDataObj);
                    this.getList();
                  }
                })
                .catch(() => {
                  this.$message.error('Information added failed');
                  this.loading.dialog = false;
                });
            }
            if (this.dialog.title === 'Update') {
              const query = Object.assign({}, this.postData);
              this.loading.dialog = true;
              handleAppsFlyerAgencyUpdate(query)
                .then((res) => {
                  this.loading.dialog = false;
                  if (res.code == 200) {
                    this.$message.success('Information updated successfully');
                    this.dialog.visible = false;
                    this.postData = Object.assign({}, postDataObj);
                    this.getList();
                  } else {
                    this.$message.error(res.message);
                    this.loading.dialog = false;
                    this.dialog.visible = false;
                    this.postData = Object.assign({}, postDataObj);
                    this.getList();
                  }
                })
                .catch(() => {
                  this.$message.error('Information updated failed');
                  this.loading.dialog = false;
                });
            }
          }
        });
      },
    },
  };
</script>

<style scoped lang="scss">
  .popover_icon {
    width: 60px;
  }
  .form_img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  @media screen and (min-width: 768px) {
    .popover_icon {
      width: 140px;
    }
  }
  @media screen and (max-width: 768px) {
    .appsflyer_account_dialog {
      ::v-deep .el-form-item__label {
        padding-bottom: 0;
      }
      ::v-deep .el-dialog__body {
        padding-top: 10px;
      }
    }
  }
</style>
